// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MyPage from './MyPage';
import Orderform from './Orderform';
import LandingPage from './LandingPage';
import './App.css';

const AppContent = () => {
  const location = useLocation();
  
  // Hide the navbar on the login page
  const isLoginPage = location.pathname === '/';
  
  return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/" element={<Orderform />} /> */}
        {/* <Route path="/" element={<MyPage />} /> */}
      </Routes>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
